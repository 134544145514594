import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss']
})
export class CompanyModalComponent implements OnInit {
  
  constructor(public bsModalRef:BsModalRef) { }

  ngOnInit() { }

  modalClose(){
    this.bsModalRef.hide();
  }

}
