import { CommonModalComponent } from './../common-modal.component';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WorkService } from './work.service';

@Component({
  selector: 'app-work-modal',
  templateUrl: './work-modal.component.html',
  styleUrls: ['./work-modal.component.scss']
})
export class WorkModalComponent implements OnInit {
  items: Array<object>;
  fixed: Array<object>;
  soon: Array<object>;

  selectedTab = 0;

  tabs = [];

  constructor(public bsModalRef: BsModalRef, private workService: WorkService, private modalService: BsModalService) {
    this.tabs = [
      workService.items,
      workService.fixed,
      workService.coming
    ]
  }

  ngOnInit() { }

  modalClose() {
    this.bsModalRef.hide();
  }

  redirectUrl(url: string) {
    if (url !== '') {
      if (url === '#') {
        const initialState = {
          str: '준비 중 입니다'
        };
        this.bsModalRef = this.modalService.show(CommonModalComponent, { initialState });
      } else {
        window.open(url);
      }
    }
  }

}
