import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  @Input() str: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  modalClose() {
    this.bsModalRef.hide();
  }

}
