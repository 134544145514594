export class VisionService {
    items: Array<{title: String, tooltips: Array<String>}> = [
        {
          "title":"틀 속의 자유",
          "tooltips":[
            "#1 자유는 소속된 개인의 권리이자, 가치 창출을 위한 행동이지만 상황에 따라 제한되어야 합니다.",
            "#2 자유엔 책임이 따르며 언제나 그 책임을 받아들일 준비가 되어 있어야 합니다.",
            "#3 자유의 제약은 집단의 존속을 위함이며 다만 합리적이어야 합니다.",
          ]
        },
        {
          "title":"미래 지향적<br/>틀",
          "tooltips":[
            "#1 '틀'은 집단의 규범, 문화, 교육, 상호 작용 방법 등을 포함한 현재의 집단 모습 그 자체를 뜻합니다.",
            "#2 '틀'은 '제약'이지만 설계자가 더 멀리 볼 줄 안다면 소속된 이들은 딱히 제약일아 느끼지 않습니다.",
            "#3 '틀'은 지극히 능력 중심으로 설계돼야 하나, 인간성을 상실한 이들을 걸러낼 수 있어야 합니다.",
            "#4 '틀'은 과거를 참고할 필요는 있지만 그대로 답습하지 않으며, 근 미래를 대비하여 설계돼야 합니다.",
            "#5 '틀'이 미래를 대비한다 하더라도 현대의 상식을 벗어나지 않은 선상에서 설계돼야 합니다.",
          ]
        },
        {
          "title":"실현 가능하나<br/>모험적인 미래",
          "tooltips":[
            "#1 '미래'는 집단이 나아가야 할 방향이지만 반드시 예측되어야 할 리스크이기도 합니다.",
            "#2 실현 가능한 미래를 한 단계씩 밟고 올라 가되 궁극적으론 영속적인 가치 창출 방법을 추구합니다.",
            "#3 틀이 바라봐야 할 '미래'는 가치 창출 가능성을 머금고 있어야 하나 쉬운 길이어선 안 됩니다.",
            "#4 여기서 말하는 '미래'는 너무 멀지 않은, 소속된 개인이 생존 가능한 기간 이내를 뜻합니다.",
            "#5 집단은 개인이 정해진 '틀'에서 벗어날 필요가 없도록 미래를 정확히 예측해야 합니다.",
          ]
        },
    ]
}