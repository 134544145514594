import { HistoryService } from './history.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./history-modal.component.scss']
})
export class HistoryModalComponent implements OnInit {
  historys: any[];

  constructor(public bsModalRef:BsModalRef, historyService: HistoryService) {
    this.historys = historyService.historys;
  }

  ngOnInit() { }

  modalClose() {
    this.bsModalRef.hide();
  }

}
