import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Partner } from 'src/app/models/partner';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class PartnersService {
  private partnerCollection: AngularFirestoreCollection<Partner>;

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
    this.partnerCollection = this.angularFirestore.collection<Partner>('partner', ref => ref.orderBy('order', 'asc'));
  }

  getAllList(): Observable<Partner[]> {
    return this.partnerCollection.snapshotChanges().pipe(map(list => {
      return list.map(a => {
        const partner = a.payload.doc.data() as Partner;
        partner.id = a.payload.doc.id;
        return partner;
      });
    }));
  }
}
