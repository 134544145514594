import { Injectable } from '@angular/core';

@Injectable()
export class HistoryService {
    historys: any[] = [
        [
            {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "bar",
                        class: [ "hidden" ],
                        style: {},
                        img: {
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/1.png" ]
                        },
                        text: "",
                        btn: false
                    }
                ],
                bottom: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2008년 11월 13일<br/><strong>좀비킹의 영상 제작소 개설</strong>",
                        btn: false
                        //btn: true
                    }
                ]
            },
            {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2009년 ~ 2013년<br/><strong>GROUP FMC</strong>로 변경",
                        btn: false
                        //btn: true
                    }
                ],
                bottom: [
                    {
                        type: "bar",
                        class: [ "hidden" ],
                        style: {},
                        img: {
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/2.png" ]
                        },
                        text: "",
                        btn: false
                    }
                ]
            },
            {
                type: "reverse",
                style: {},
                top: [
                    {
                        type: "bar",
                        class: [ "hidden" ],
                        style: {},
                        img: {
                            boxing: true,
                            src: [ "../../../../assets/images/about/history/3.png" ]
                        },
                        text: "",
                        btn: false
                    },
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "30px" },
                        img: {},
                        text: "2015년 1월 21일<br/><strong>개인 사업자 설립</strong>",
                        btn: false
                    },
                ],
                bottom: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2014년<br/><strong>채널 좀비왕 활동 시작</strong>",
                        btn: false
                        //btn: true
                    },
                    {
                        type: "bar",
                        class: [ "hidden", "left0" ],
                        style: { left: "-60px" },
                        img: { 
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/4.png" ]
                         },
                        text: "",
                        btn: false
                    }
                ]
            },
            {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2015년 ~<br/><strong>DIA TV와의 제휴</strong>",
                        btn: false
                        //btn: true
                    }
                ],
                bottom: [
                    {
                        type: "bar",
                        class: [],
                        style: {},
                        img: {
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/5.png" ]
                        },
                        text: "",
                        btn: false
                    }
                ]
            },
            {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "bar",
                        class: [ "hidden", "left0" ],
                        style: {},
                        img: { 
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/6.png" ]
                        },
                        text: "",
                        btn: false
                    },
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "50px" },
                        img: {},
                        text: "2017년<br/><strong>연매출 1억 달성</strong>",
                        btn: false
                    }
                ],
                bottom: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2017년 9월<br/><strong>채널 좀비왕 법인화</strong>",
                        btn: false
                        //btn: true
                    },
                    {
                        type: "bar",
                        class: [ "hidden", "left0" ],
                        style: { left: "-20px" },
                        img: { 
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/4.png" ]
                         },
                        text: "",
                        btn: false
                    }
                ]
            }
        ],

        [
            {
                type: "normal",
                style: {
                    "flex": "0.8"
                },
                top: [
                    {
                        type: "bar",
                        class: [],
                        style: {},
                        img: {
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/7.png" ] 
                        },
                        text: "",
                        btn: false
                    }
                ],
                bottom: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2018년 1월<br/><strong>영상 편집 업체 '킹스 커터'와 협력</strong>",
                        btn: false
                        //btn: true
                    }
                ]
            },
            {
                type: "normal",
                // style: {
                //     "flex": "0.7"
                // },
                top: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2018년 8월<br/><strong>기술 보증 기금</strong>",
                        btn: false
                        //btn: true
                    }
                ],
                bottom: [
                    {
                        type: "bar",
                        class: [ "hidden" ],
                        style: {},
                        img: { 
                            boxing: true,
                            src: [ "../../../../assets/images/about/history/8.png" ]
                        },
                        text: "",
                        btn: false
                    }
                ]
            },
            {
                type: "reverse",
                style: {
                    flex: "2"
                },
                top: [
                    {
                        type: "bar",
                        class: [ "hidden", "left0" ],
                        style: { },
                        img: { 
                            boxing: true,
                            src: [ "../../../../assets/images/about/history/9.png" ]
                        },
                        text: "",
                        btn: false
                    },
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "210px" },
                        img: {},
                        text: "2018년<br/><strong>연매출 10억 달성</strong>",
                        btn: false
                    },
                ],
                bottom: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2018년 9월<br/><strong>상호 변경 '코퍼레이션 플루토니움'</strong>",
                        btn: false
                        //btn: true
                    },
                    {
                        type: "bar",
                        class: [ "hidden", "left0" ],
                        style: { left: "83px" },
                        img: { 
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/4.png" ]
                         },
                        text: "",
                        btn: false
                    }
                ]
            },
            {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "text",
                        class: [],
                        style: {},
                        img: {},
                        text: "2019년<br/><strong>체제 개편</strong>",
                        btn: false
                        //btn: true
                    },
                    {
                        type: "bar",
                        class: [ "hidden", "left0" ],
                        style: { left: "170px" },
                        img: { 
                            boxing: true,
                            src: [ "../../../../assets/images/about/history/11.png" ]
                        },
                        text: "",
                        btn: false
                    }
                ],
                bottom: [
                    {
                        type: "bar",
                        class: [ "hidden" ],
                        style: {},
                        img: { 
                            boxing: true,
                            src: [
                                "../../../../assets/images/about/history/10.png",
                                "../../../../assets/images/about/history/arrow.png",
                                "../../../../assets/images/about/history/10.png",
                                "../../../../assets/images/about/history/10.png"
                            ]
                        },
                        text: "",
                        btn: false
                    },
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "60px" },
                        img: {},
                        text: "2020년<br/><strong>쉘터 런칭</strong>",
                        btn: false
                        //btn: true
                    }
                ]
            },
        ],
        [
            {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: {},
                        img: {
                        },
                        text: "2021년<br /><strong>연매출 50억 돌파</strong>",
                        btn: false
                    }
                ],
                bottom: [{
                    type: "bar",
                    class: [ "left0" ],
                    style: { },
                    img: { 
                        boxing: false,
                        src: [ "../../../../assets/images/about/history/4.png" ]
                        },
                    text: "",
                    btn: false
                }]
            },
            {
                type: "normal",
                style: {},
                bottom: [
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "-50px" },
                        img: {
                        },
                        text: "2022년<br /><strong>연매출 90억 돌파</strong>",
                        btn: false
                    }
                ],
                top: [{
                    type: "bar",
                    class: [ "hidden", "left0" ],
                    style: { left: "-50px" },
                    img: { 
                        boxing: false,
                        src: [ "../../../../assets/images/about/history/4.png" ]
                        },
                    text: "",
                    btn: false
                }]
            },
            // {
            //     type: "normal",
            //     style: {},
            //     bottom: [
            //         {
            //             type: "bar",
            //             class: [ "hidden", "left0", "fit-img" ],
            //             style: { left: "-100px" },
            //             img: { 
            //                 src: [ "../../../../assets/images/logo/ainsoph.png" ],
            //                 boxing: true
            //             },
            //             text: "",
            //             btn: false
            //         },
                 
            //     ],
            //     top: [
            //         {
            //             type: "text",
            //             class: [ "left0" ],
            //             style: { left: "-100px" },
            //             img: {},
            //             text: "2022년 1월<br/><strong>카페 아인 소프 설립</strong>",
            //             btn: false
            //         }
                 
            //     ]
            // },
            {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "bar",
                        class: [ "hidden", "left0", "fit-img" ],
                        style: { left: "-110px" },
                        img: { 
                            src: [ "../../../../assets/images/logo/arawn.png" ],
                            boxing: true
                        },
                        text: "",
                        btn: false
                    },
                 
                ],
                bottom: [
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "-110px" },
                        img: {},
                        text: "2022년 2월 22일<br/><strong>1994 JR₁<br />아라운 스튜디오 설립</strong>",
                        btn: false
                    }
                 
                ]
            },
            {
                type: "normal",
                style: {},
                bottom: [
                    {
                        type: "bar",
                        class: [ "hidden", "left0"],
                        style: { left: "-110px" },
                        img: { 
                            src: [ "../../../../assets/images/about/history/9.png" ],
                            boxing: true
                        },
                        text: "",
                        btn: false
                    },
                 
                ],
                top: [
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "-110px" },
                        img: {},
                        text: "2022년 11월<br/><strong>플루토니움 데모 런칭</strong>",
                        btn: false
                    }
                 
                ]
            },
          
             {
                type: "normal",
                style: {},
                bottom: [
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "-110px" },
                        img: {},
                        text: "2022년 11월<br/><strong>패러블: 네이버 Z 투자</strong>",
                        btn: false
                    }
                ],
                top: [
                    {
                        type: "bar",
                        class: [ "left0" ],
                        style: { left: "-110px" },
                        img: { 
                            boxing: false,
                            src: [ "../../../../assets/images/about/history/4.png" ]
                            },
                        text: "",
                        btn: false
                    },
                ]
            },
             {
                type: "normal",
                style: {},
                top: [
                    {
                        type: "text",
                        class: [ "left0" ],
                        style: { left: "-100px" },
                        img: {},
                        text: "2023년<br/><strong>쉘터 블록체인 연계</strong>",
                        btn: false
                    }
                ],
                bottom: [
                    {
                        type: "bar",
                        class: [ "hidden", "left0" ],
                        style: { left: "-100px" },
                        img: { 
                            src: [ "../../../../assets/images/about/history/parable.png" ],
                            style: { "width": "39px" },
                            boxing: true
                        },
                        text: "",
                        btn: false
                    },
                ]
            },
        ]
    ]
}