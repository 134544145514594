import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, ElementRef, HostListener, ɵɵresolveBody } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PeopleService } from './people.service';
import * as $ from 'jquery';
import 'slick-carousel';

@Component({
  selector: 'app-people-modal',
  templateUrl: './people-modal.component.html',
  styleUrls: ['./people-modal.component.scss']
})
export class PeopleModalComponent implements OnInit, AfterViewInit {
  peoples: Array<{name: string, position: string, imgUrl: string, description: string}>;
  name: string;
  position: string;
  imgUrl: string;
  description: string;
  body: HTMLElement = document.body;
  bodyWidth:number = this.body.clientWidth;
  slickValid:boolean = false;
  @ViewChildren('carousel') carousel: any;
  @ViewChild('modalBody', {static: false}) modalBody:ElementRef;

  constructor(public bsModalRef:BsModalRef, peopleService: PeopleService) {
    this.peoples = peopleService.peoples;
    this.name = this.peoples[0].name;
    this.position = this.peoples[0].position;
    this.imgUrl = this.peoples[0].imgUrl;
    this.description = this.peoples[0].description;
  }

  ngOnInit() { }

  ngAfterViewInit(){
    if(this.bodyWidth > 1150){
      this.Slick();
    }
  }

  @HostListener('window:resize') onResize() {
    if(this.body){
      this.bodyWidth = this.body.clientWidth;
      if(this.bodyWidth <= 1150){
        this.unSlick();
      }else{
        //body width 조절에 따라 계속적으로 호출되기 때문에
        //debounce함수 적용하여 딜레이
        this.debounce(this.Slick, 50);
      }
    }
  }

  debounce(fn, delay){
    var timer = null;
    return function(){
      clearTimeout(timer);
      timer = setTimeout(function(){
        fn.apply();
      }, delay);
    };
  }
  unSlick(){
    if(this.slickValid){
      $(this.carousel.first.nativeElement).slick('unslick');
      this.slickValid = false;
    }
  }
  Slick(){ 
    if(!this.slickValid){
      if(this.peoples.length > 9){
        $(this.carousel.first.nativeElement).slick({
          autoplay: false,
          slidesToShow: 8,
          slidesToScroll: 1,
          arrows: true
        });
        this.slickValid = true;
      }
    }
  }

  handleClickPeople(index:number) {
    this.name = this.peoples[index].name;
    this.position = this.peoples[index].position;
    this.imgUrl = this.peoples[index].imgUrl;
    this.description = this.peoples[index].description;
    $(this.modalBody.nativeElement).animate({scrollTop:0}, 500);
  }

  modalClose() {
    this.bsModalRef.hide();
  }
}
