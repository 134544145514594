import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Partner } from 'src/app/models/partner';
import { PartnersService } from './partners.service';

@Component({
  selector: 'app-partners-modal',
  templateUrl: './partners-modal.component.html',
  styleUrls: ['./partners-modal.component.scss']
})
export class PartnersModalComponent implements OnInit {
  partnerList: Partner[] = [];
  extraArray: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private partnersService: PartnersService
  ) {}

  ngOnInit() {
    this.partnersService.getAllList().subscribe(data => {
      this.partnerList = data;
      this.extraArray = this.getExtraArray();
    });
  }

  modalClose() {
    this.bsModalRef.hide();
  }

  goUrl(url: string) {
    window.open(url);
  }

  getExtraArray(): any[] {
    const row = 7;
    const unit = Math.floor(this.partnerList.length % row);
    if ((row - unit) >= row) {
      return new Array(row - unit);
    } else {
      return new Array((row - unit) + row);
    }
  }
}
