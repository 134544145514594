import { CommonModalComponent } from './../modal/common-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SlidePanelService } from './slide-panel.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss']
})
export class SlidePanelComponent implements OnInit {
  modalRef: BsModalRef;
  items: Array<object>;
  // tslint:disable-next-line: variable-name
  _activeSlideIndex = 0;

  constructor(private slidePanelService: SlidePanelService, private modalService: BsModalService) {
    this.items = slidePanelService.items;
  }

  ngOnInit() { }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    // 처음 페이지 로드 시 첫번째 비디오 재생
    const firstSlide: HTMLMediaElement = document.querySelector('#bg_0');
    if (firstSlide.childElementCount > 0) {
      firstSlide.muted = true;
      firstSlide.play();
    }
  }

  redirectUrl(url: string) {
    if (url !== '') {
      if (url === '#') {
        const initialState = {
          str: '준비 중 입니다'
        };
        this.modalRef = this.modalService.show(CommonModalComponent, { initialState });
      } else {
        window.open(url);
      }
    }
  }

  get activeSlideIndex(): number {
    return this._activeSlideIndex;
  }

  set activeSlideIndex(newIndex: number) {
    // 이전 슬라이드
    const prevSlide: HTMLMediaElement = document.querySelector('#bg_' + this._activeSlideIndex);
    // 현재 슬라이드
    const currentSlide: HTMLMediaElement = document.querySelector('#bg_' + newIndex);

    // 슬라이드 넘어가면 이전비디오 재생중지/ 다음비디오 0초부터 재생시작
    if (this._activeSlideIndex !== newIndex) {

      if (prevSlide) {
        if (prevSlide.childElementCount > 0) {
          prevSlide.pause();
          prevSlide.currentTime = 0;
        }
      }

      if (currentSlide) {
        if (currentSlide.childElementCount > 0) {
          currentSlide.play();
        }
      }
    }

    this._activeSlideIndex = newIndex;
  }
}
