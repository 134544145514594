import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VisionService } from './vision.service';


@Component({
  selector: 'app-vision-modal',
  templateUrl: './vision-modal.component.html',
  styleUrls: ['./vision-modal.component.scss'],
})
export class VisionModalComponent implements OnInit {
  items: Array<{title: String, tooltips: Array<String>}>;
  
  constructor(public bsModalRef:BsModalRef, visionService: VisionService) {
    this.items = visionService.items;
  }

  ngOnInit() { }

  modalClose() {
    this.bsModalRef.hide();
  }
  
}
