import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';

import { CommonModalComponent } from './components/modal/common-modal.component';
import { CompanyModalComponent } from './components/modal/company-modal/company-modal.component';
import { WorkModalComponent } from './components/modal/work-modal/work-modal.component';
import { GrowthModalComponent } from './components/modal/growth-modal/growth-modal.component';
import { PeopleModalComponent } from './components/modal/people-modal/people-modal.component';
import { VisionModalComponent } from './components/modal/vision-modal/vision-modal.component';
import { PartnersModalComponent } from './components/modal/partners-modal/partners-modal.component';
import { HistoryModalComponent } from './components/modal/history-modal/history-modal.component';

import { PeopleService } from './components/modal/people-modal/people.service';
import { VisionService } from './components/modal/vision-modal/vision.service';
import { PartnersService } from './components/modal/partners-modal/partners.service';
import { HistoryService } from './components/modal/history-modal/history.service';
import { WorkService } from './components/modal/work-modal/work.service';
import { GrowthService } from './components/modal/growth-modal/growth.service';
import { SlidePanelService } from './components/slide-panel/slide-panel.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { MembersModalComponent } from './components/modal/members-modal/members-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    NotFoundComponent,
    SlidePanelComponent,
    CompanyModalComponent,
    WorkModalComponent,
    GrowthModalComponent,
    PeopleModalComponent,
    VisionModalComponent,
    PartnersModalComponent,
    HistoryModalComponent,
    CommonModalComponent,
    MembersModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    FontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
  ],
  entryComponents: [
    CommonModalComponent,
    CompanyModalComponent,
    WorkModalComponent,
    GrowthModalComponent,
    PeopleModalComponent,
    VisionModalComponent,
    PartnersModalComponent,
    HistoryModalComponent,
    MembersModalComponent
  ],
  providers: [
    PeopleService,
    VisionService,
    PartnersService,
    HistoryService,
    WorkService,
    GrowthService,
    SlidePanelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
