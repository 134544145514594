export class WorkService {
  items: Array<object> = [
    {
      title: '패러블',
      imageUrl: '../../../../assets/images/about/company/parable.png',
      description: [
        '유튜버/스트리머 MCN',
        '콘텐츠 제작 컨설팅',
        '크리에이터 매니지먼트',
        '인플루언서 마케팅',
        '크리에이터 마케팅'
      ],
      btnText: '웹사이트 바로가기',
      href: 'https://parable-asia.com/',
    },
    {
      title: '쉘터',
      imageUrl: '../../../../assets/images/about/company/shelter.png',
      description: [
        '창작자 소셜 네트워크',
        '팬 커뮤니티 제공',
        '창작자 후원 기능',
        '콘텐츠 판매 기능',
        '창작자 관련 기능 일체'
      ],
      btnText: '웹사이트 바로가기',
      href: 'https://shelter.id/',
    },
    // {
    //   title: '아인소프',
    //   imageUrl: '../../../../assets/images/logo/ainsoph.png',
    //   description: [
    //     '디저트 카페',
    //     '작품 전시',
    //     '디지털 X 아날로그',
    //   ],
    //   btnText: '웹사이트 바로가기',
    //   href: 'https://instagram.com/cafe_ainsoph'
    // },
    {
      title: '1994 JR₁ 아라운 스튜디오',
      imageUrl: '../../../../assets/images/about/company/arawn.png',
      description: [
        '게임 제작'
      ],
      btnText: '웹사이트 바로가기',
      href: 'https://twitter.com/arawnstudio'
    },
    {
      title: '마도공방',
      imageUrl: '../../../../assets/images/logo/mr.png',
      description: [
        '사주 상담',
        '개명 상담',
        '탈리스만 제작'
      ],
      btnText: '웹사이트 바로가기',
      href: 'https://www.instagram.com/mado_workshop'
    },
  ];

  fixed: Array<object> = [
    {
      title: '채널 좀비왕',
      imageUrl: '../../../../assets/images/about/company/chzbk.png',
      // description: [
      //   '순수 창작 그룹',
      //   '원화 & 만화 제작',
      //   '웹 애니메이션 제작',
      //   '게임 제작',
      //   '음악 작곡',
      //   '각종 예술 전반'
      // ],
      // btnText: '웹사이트 바로가기',
      // href: 'https://chzbk.com/',
    },
    {
      title: '킹스 커터',
      imageUrl: '../../../../assets/images/about/company/kingscutter.png',
      // description: [
      //   '영상 편집 업체',
      //   '영상 썸네일 제작',
      //   '채널 아트 제작',
      //   '영상 효과 제작',
      //   '인트로 영상 제작',
      //   '기타 영상 제작'
      // ],
      // btnText: '웹사이트 바로가기',
      // href: 'https://www.kingscutter.com/',
    },
    {
      title: '아인소프',
      imageUrl: '../../../../assets/images/logo/ainsoph.png',
      // description: [
      //   '디저트 카페',
      //   '작품 전시',
      //   '디지털 X 아날로그',
      // ],
      // btnText: '웹사이트 바로가기',
      // href: 'https://instagram.com/cafe_ainsoph'
    },
    {
      title: '킹스 우퍼',
    },
  ];

  coming: Array<object> = [
    // {
    //   title: '마법사 공방',
    //   imageUrl: '../../../../assets/images/logo/mr.png',
    //   // description: [
    //   //   '점성술, 주역, 사주',
    //   //   '탈리스만 제작'
    //   // ]
    // },
    {
      title: '아포칼립틱 프루프리더즈',
      // description: [
      //   '정당'
      // ]
    },
    {
      title: '옴니즘',
      // description: [
      //   '종교 플랫폼'
      // ]
    },
    {
      title: '시드 메이커',
      // description: [
      // ]
    },
    {
      title: '디베이트',
      // description: [
      //   // '정당'
      // ]
    }
  ];
}
