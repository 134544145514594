export class SlidePanelService {
    items: Array<object> = [
        {
            title: '',
            titleColor: '',
            description: '',
            descriptionColor: '',
            btnText: '',
            btnColor: '',
            btnTextColor: '',
            href: '',
            image: '../../../assets/images/main_banner/plutonium.png',
            video:
            {
                mp4: '../../../assets/video/bg_1.mp4'
            },
            videoClass: '',
            layout: {
                style: ''
            }
        },
        {
            title: '패러블 엔터테인먼트',
            titleColor: '#ffffff',
            description: '우리만의 이야기를 만들어 나가는',
            descriptionColor: '#ffffff',
            btnText: '바로가기',
            btnColor: '#00CCB2',
            btnTextColor: '#fff',
            href: 'https://parable-asia.com/',
            image: '../../../assets/images/main_banner/parabrick.png',
            video: '',
            videoClass: [ 'fill' ],
            layout: {
                style: ''
            },
            box: 'right'
        },
        // {
        //     title: '킹스 커터',
        //     titleColor: '',
        //     description: '유튜브 영상 편집 및 썸네일 제작 전문',
        //     descriptionColor: '',
        //     btnText: '바로가기',
        //     btnColor: '#a28e1f',
        //     href: 'https://www.kingscutter.com/',
        //     image: '../../../assets/images/main_banner/kings-cutter.png',
        //     video: {
        //         mp4: '../../../assets/video/bg_3.mp4'
        //     },
        //     videoClass: [ 'fill' ],
        //     layout: {
        //         style: ''
        //     }
        // },
        // {
        //     title: '채널 좀비왕',
        //     titleColor: '',
        //     description: '웹 애니메이션, 웹툰, 원화, 게임 제작',
        //     descriptionColor: '',
        //     btnText: '바로가기',
        //     btnColor: '#a21f29',
        //     href: 'https://chzbk.com/',
        //     image: '../../../assets/images/main_banner/chzbk.png',
        //     video: {
        //         mp4: '../../../assets/video/bg_2.mp4'
        //     },
        //     videoClass: [ 'fill' ],
        //     layout: {
        //         style: ''
        //     }
        // },
        {
            title: '쉘터 (SHELTER)',
            titleColor: '#000',
            description: '크리에이터 후원 기반의 팬 커뮤니티 플랫폼',
            descriptionColor: '#000',
            btnText: '바로가기',
            btnColor: '#60a0e0',
            btnTextColor: '#fff',
            href: 'https://shelter.id/',
            image: '../../../assets/images/main_banner/shelter_banner.png',
            video: '',
            videoClass: [ 'fill', 'left20' ],
            layout: {
                style: ''
            },
            box: 'left'
        },
        // {
        //     title: '카페 아인소프',
        //     titleColor: '#fff',
        //     description: '디저트 카페 X NFT 전시',
        //     descriptionColor: '#fff',
        //     btnText: '바로가기',
        //     btnColor: '#fff',
        //     btnTextColor: '#000',
        //     href: 'https://instagram.com/cafe_ainsoph',
        //     image: '../../../assets/images/main_banner/ainsoph-splash.png',
        //     video: '',
        //     videoClass: [ 'fill' ],
        //     layout: {
        //         style: ''
        //     },
        //     box: 'right'
        // },
        {
            title: '1994 JR&#8321; 아라운 스튜디오',
            titleColor: '#fff',
            description: '게임 플루토니움 개발 팀',
            descriptionColor: '#fff',
            btnText: '바로가기',
            btnColor: '#474252',
            btnTextColor: '#fff',
            href: 'https://twitter.com/arawnstudio',
            image: '../../../assets/images/main_banner/arawn-splash.jpg',
            video: '',
            videoClass: [ 'fill', 'right20' ],
            layout: {
                style: ''
            },
            box: 'right'
        },
       
    ];
}
