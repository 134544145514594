// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAA5z5odYaFV_u4D5VJlUnr_KUE9d8B-ts",
    authDomain: "corporation-plutonium.firebaseapp.com",
    databaseURL: "https://corporation-plutonium.firebaseio.com",
    projectId: "corporation-plutonium",
    storageBucket: "corporation-plutonium.appspot.com",
    messagingSenderId: "577995226285",
    appId: "1:577995226285:web:2efaca7177abe66fcb9a24"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
