export class PeopleService {
    peoples: Array<{name: string, position: string, imgUrl: string, description: string, display: boolean}> = [
        {
            "name": "'진정한'",
            "position": "수평적 조직 문화",
            "imgUrl": "",
            "description": "코퍼레이션 플루토니움은 '무'로부터 탄생했기에 위계에 따른 강제성이 존재할 수 없었습니다. 이곳에서의 위계는 회사의 틀을 유지하는 수준으로만 존재합니다. 태생부터 그래왔고 앞으로도 그러할 것입니다. 때문에 우린 상식을 지키는 선에서 오직 '능력'만을 취우선 순위로 생각합니다.<br/><br/>물론, '연공'을 경시하는 것은 아닙니다. 경험치를 무시할 수는 없기 때문입니다. 다만 능력보다 연공이 앞서는 경우는 있을 수 없습니다. 우리는 더욱 성장해야만 하기 때문입니다.",
            "display": false
        },
        {
            "name": "양정훈",
            "position": "대표이사",
            "imgUrl": "",
            "description": "저는 2008년부터 지금까지 코퍼레이션 플루토니움의 대표이사직을 맡고 있는 양정훈이라고 합니다. 저는 한 배를 타기로 선택해준 주변 동료들과 찬란하면서도 행복한 공간을 만드는데 주력하고 있습니다. 때문에 제 업무는 정해져 있진 않습니다. 굳이 꼽아보자면 미래를 내다보며 계획을 수립하는 것, 벌어지는 상황마다 가장 효율적인 길을 선택하는 것, 그리고 사원분들께서 기꺼이, 즐겁게 일 할 환경을 유지하는 것 정도입니다.<br/><br/>경험과 실력이 부족한 탓에 위에 말한 역할을 제대로 해내지 못하여 많은 구설수에 오르기도 했습니다. 다만 같은 일에서 두 번 실수하지 않는다는 일념하에 정진하고 있습니다. 포기하는 것은 동료들에 대한 배신이며, 제 성장이 곧 지난 과오에 대한 속죄라고 믿기 때문입니다. ",
            "display": true
        },
        {
            "name": "김영비",
            "position": "이사",
            "imgUrl": "",
            "description": "김영비 이사님은 2017년, 코퍼레이션 플루토니움에 입사 했습니다. 당사가 크루 혹은 스튜디오의 이미지에서 벗어나 하나의 업체로서 거듭날 수 있도록 많은 노력을 기울여 주셨습니다.<br/><br/>김영비 이사님이 입사하실 당시 당사가 인플루언서 마케팅을 소화할 인력이 부족하던 찰나, 이에 적합한 인재를 미리 물색하시어 영업 팀을 꾸리는데 큰 도움을 주셨습니다. 또한, 당사의 플랫폼 개발에 중요한 역할을 할 개발자분 역시 김영비 이사님께서 끌어 당겨 주셨습니다.<br/><br/>현재도 회사 내부의 일을 총괄적으로 담당하며 당사의 발전에 부단한 노력을 기울이고 계십니다.",
            "display": true
        },
        {
            "name": "강슬비",
            "position": "팀장 (마케터)",
            "imgUrl": "",
            "description": "강슬기 팀장님은 2019년, 코퍼레이션 플루토니움에 입사 했습니다. 강슬기 팀장님께서 입사하시기 전까지 당사는 수없이 많은 광고주들을 신 포도 마냥 바라만 봐야 했습니다. 접촉 라인이 전무했기 때문입니다. 그러한 찰나에 강슬기 팀장님은 당사를 선택하여 입사하셨습니다. 강슬기 팀장님께서 마케팅 영역에 오랜 경력이 있으셨던 덕에, 당사는 마케팅 부서를 하나부터 열까지 새로 구성할 필요가 없었습니다.<br/><br/>현재는 당사의 마케팅 프로세스 안정과 새로운 광고주 모색, 상품 개발 그리고 각 마케팅 건이 차질 없이 진행되도록 부단히 노력하고 계십니다. 광고주와 크리에이터 사이를 조율하는건 매우 어려운 일인 탓에 흰머리가 금방 느실까 걱정입니다.",
            "display": true
        },
        {
            "name": "김종민",
            "position": "매니저 (마케터)",
            "imgUrl": "",
            "description": "김종민 매니저님은 2019년 초 코퍼레이션 플루토니움에 입사 하셨습니다. 마케팅 업무 전반에서 크리에이터와 광고주 사이를 조율하는 데 힘 써주고 계시는데, 크리에이터 입장에선 도저히 미워할 수 없는 분위기를 가지고 계십니다. 이러한 분위기는 마찰 없이 마케팅 건을 처리할 수 있게 하여 당사에 큰 도움이 되고 있습니다. 이 분위기는 만나자마자 알긴 어렵지만, 시간이 지나면 자연스레 알게 됩니다. 신기한 일입니다.<br/><br/>한편, 광고주 모색이나 신규 크리에이터 영입 등에도 힘 써주고 계십니다. 다른 동료는 김종민 매니저님이 그렇게 웃긴 사람이라는 이야기를 하는데 저도 그 모습을 어서 보고 싶습니다. 일을 부대껴서 하는 사람만 볼 수 있는 특전인가 봅니다.",
            "display": true
        },
        {
            "name": "조성민",
            "position": "매니저 (운영)",
            "imgUrl": "",
            "description": "조성민 매니저님은 코퍼레이션 플루토니움의 전신인 채널 좀비왕의 설립 멤버로 오랜 기간 잔뼈가 굵은 원로 인력입니다. 당사가 크루 수준의 업체에서 벗어나려는 기간 동안엔 애석하게도 국가의 부름을 받아 군대 생활을 하고 있었습니다. 이후, 군대를 전역한 조성민 매니저는 여러 사람들과 편히 소통하는 본인의 장기를 살려 크리에이터 매니저로서 다시금 입사 하였습니다.<br/><br/>그는 당사의 직원으로서 회사의 이익과 동시에 크리에이터들의 이익 역시 중요하다고 생각하여 그들을 변호하는 역할을 맡고 있습니다. 또한, 회사가 크리에이터들의 고충을 헤아리지 못 할 때 그들의 입장에 서서 고충의 해소를 줒아하거나 애초에 고충이 생기지 않도록 하는 역할을 합니다.",
            "display": true
        },
        {
            "name": "선현아",
            "position": "매니저 (운영)",
            "imgUrl": "",
            "description": "선현아 매니저님은 과거 채널 조밉왕 시절 크리에이터로서 입단하셨으나 지금은 디자인 및 실무 전반을 처리하는 운영팀 직원이 되셨습니다. 크리에이터 시절, 선현아 매니저님은 '이사잉'이라는 닉네임을 사용하였으며 오랜 기간 만화를 연재한 이력이 있습니다. 이때 쌓아둔 소속 창작자들과의 친목 덕에 그들을 관리해야 하는 운영팀 인력으로서도 큰 도움이 되고 있습니다.<br/><br/>이외에도 꾸준히 쌓아왔던 디자인 실력을 바탕으로 당사에 필요한 수많은 디자인 업무를 처리 해주고 있습니다. 또한, 영상 편집 실력도 수준급으로 실무 및 디자인 업무 외 시간엔 영상 편집으로 일과 시간을 보내십니다. 꾸준히 발전해 나가는 다재다능한 매니저 분이십니다.",
            "display": true
        },
        {
            "name": "김영천",
            "position": "팀장 (개발)",
            "imgUrl": "",
            "description": "김영천 팀장님은 제 역사에서 가뭄의 단비 같은 존재라고 할 수 있습니다. 수년 간 마음이 맞는 개발자를 찾아 다니던 와중, 김영비 이사님의 권유로 2019년, 코퍼레이션 플루토니움에 입사하시게 되었습니다.<br/><br/>김영천 팀장님은 당사의 창작자 플랫폼인 '쉘터'의 개발 총 책임을 맡고 계십니다. 이 새로운 플랫폼의 개발을 위해 다니시던 회사를 그만 두시고 넘어 오셨을 정도로 열의가 넘치십니다. 제가 제시한 플랫폼의 사이즈가 너무 방대하여, 수 년 동안 업데이트 해야할 것들이 산더미 같이 쌓여 있음에도 불구하고 오직 '재밌을 것 같다'는 이유로 뛰어 드셨습니다.<br/><br/>사실 아무리 아이디어가 좋다 하더라도 자신의 상황을 희생하면서까지 투자하기로 결정하는 건 쉬운 일이 아니니 찬란한 미래로 보답 드려야겠습니다.",
            "display": true
        },
    ]
}
