import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { faVolumeUp, faVolumeMute, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isPlay: boolean = false;
  faVolumeUp:IconDefinition = faVolumeUp;
  faVolumeMute:IconDefinition = faVolumeMute;
  volumn:IconDefinition = this.faVolumeMute;
  @ViewChild('audio', {static:false}) audioEl: ElementRef;

  constructor(private router:Router) { }

  ngOnInit() { }

  audioPlay(){
    if(!this.isPlay){
      this.audioEl.nativeElement.play();
      this.volumn = this.faVolumeUp;
      this.isPlay = true;
    }else{
      this.audioEl.nativeElement.pause();
      this.volumn = this.faVolumeMute;
      this.isPlay = false;
    }
  }
  
  home:Function = (k) => {
    this.router.navigateByUrl('/');
  }
  
}
