export interface GrowthData {
  caption: string;
  sales: string;
  salesNum?: number;
}
export interface GrowthItem {
  name: string;
  id: string;
  logo: string;
  max?: number;
  data: Array<GrowthData>;
}
export class GrowthService {
  items: Array<GrowthItem> = [
    {
      name: '패러블',
      id: 'parable',
      logo: '/assets/images/about/company/parable.png',
      data: [
        // rate 5단위 입력 최대 100
        {
          caption: '2015',
          sales: '￦ 14,284,799'
        },
        {
          caption: '2016',
          sales: '￦ 72,168,205'
        },
        {
        caption: '2017',
          sales: '￦ 326,254,942'
        },
        {
          caption: '2018',
          sales: '￦ 1,372,490,291'
        },
        {
          caption: '2019',
          sales: '￦ 3,031,615,625'
        },
        {
          caption: '2020',
          sales: '￦ 6,083,320,384'
        },
        {
          caption: '2021',
          sales: '￦ 9,480,000,000'
        },
      ]
    },
    {
      name: '쉘터',
      id: 'shelter',
      logo: '/assets/images/about/company/shelter.png',
      data: [
        {
          caption: '2020',
          sales: '￦ 69,037,574'
        },
        {
          caption: '2021',
          sales: '￦ 80,991,199'
        },
      ]
    },
    // {
    //   name: '아인소프',
    //   id: 'is',
    //   logo: '/assets/images/logo/ainsoph.png',
    //   data: []
    // },
    {
      name: '1994 JR₁ 아라운 스튜디오',
      id: 'as',
      logo: '/assets/images/about/company/arawn.png',
      data: []
    }
  ];
}
