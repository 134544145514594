import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GrowthItem, GrowthService } from './growth.service';

@Component({
  selector: 'app-growth-modal',
  templateUrl: './growth-modal.component.html',
  styleUrls: ['./growth-modal.component.scss']
})
export class GrowthModalComponent implements OnInit {
  public items:Array<GrowthItem>;
  public selectedTab: number;

  constructor(public bsModalRef:BsModalRef, private growthService: GrowthService) {
    this.items = growthService.items;
    this.selectedTab = 0;

    this.items.forEach((i) => {
      i.data.forEach((j) => j.salesNum = Number(j.sales.replace(/[^\d]/g, '')));
      i.max = i.data.reduce((p, c) => Math.max(p, c.salesNum), 1);
    });
  }

  ngOnInit() { }

  modalClose() {
    this.bsModalRef.hide();
  }
  
}
