import { CommonModalComponent } from './../components/modal/common-modal.component';
import { Component, OnInit, ComponentRef, ViewChild } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CompanyModalComponent } from '../components/modal/company-modal/company-modal.component';
import { WorkModalComponent } from '../components/modal/work-modal/work-modal.component';
import { GrowthModalComponent } from '../components/modal/growth-modal/growth-modal.component';
import { PeopleModalComponent } from '../components/modal/people-modal/people-modal.component';
import { VisionModalComponent } from '../components/modal/vision-modal/vision-modal.component';
import { PartnersModalComponent } from '../components/modal/partners-modal/partners-modal.component';
import { HistoryModalComponent } from '../components/modal/history-modal/history-modal.component';
import { MembersModalComponent } from '../components/modal/members-modal/members-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  modalRef: BsModalRef;
  modalComponent: any;

  constructor(private modalService: BsModalService) {
  }

  ngOnInit() {

  }

  openModal = (kind) => {
    switch (kind) {
      case 'company': this.modalComponent = CompanyModalComponent; break;
      case 'work': this.modalComponent = WorkModalComponent; break;
      case 'growth': this.modalComponent = GrowthModalComponent; break;
      case 'people': this.modalComponent = PeopleModalComponent; break;
      case 'vision': this.modalComponent = VisionModalComponent; break;
      case 'partners': this.modalComponent = PartnersModalComponent; break;
      case 'history': this.modalComponent = HistoryModalComponent; break;
      case 'members': this.modalComponent = MembersModalComponent; break;
      default: this.modalComponent = null;
    }
    this.modalRef = this.modalService.show(this.modalComponent);
  }

  alertModal(str: string) {
    const initialState = {
      str
    };
    this.modalRef = this.modalService.show(CommonModalComponent, { initialState });
  }

}
