import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-members-modal',
  templateUrl: './members-modal.component.html',
  styleUrls: ['./members-modal.component.scss']
})
export class MembersModalComponent implements OnInit {
  
  constructor(public bsModalRef:BsModalRef) { }

  ngOnInit() { }

  modalClose(){
    this.bsModalRef.hide();
  }

}
